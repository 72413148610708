<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Agent Transactions",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Agent Transactions",
      items: [
        {
          text: "Admin",
          href: "/dashboard",
        },
        {
          text: "Agent Transactions",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "profile.firstName", sortable: true , label: "First Name"},
        { key: "profile.lastName", sortable: true , label: "Last Name"},
        { key: "profile.email", sortable: true, label: "Email" },
        { key: "profile.phone", sortable: true, label: "Phone"  },
        { key: "profile.address", sortable: true, label: "Address"  },
        { key: "profile.username", sortable: true, label: "Username"  },
        { key: "profile._id", sortable: true, label: "View Transactions" },
      ],
      agentsData: [],
      loading: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.agentsData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getAgents()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getAgents() {
      this.loading = true
      try {
        const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/admin/users/list-agents?page=1&limit=50&sortBy`);
        this.agentsData = response.data.data
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="loading">
        <b-spinner class="m-2 m-auto" variant="primary" role="status"></b-spinner>
    </div>
    <div v-else>
       <b-alert show dismissible variant="info">
      <i class="mdi mdi-alert-circle-outline mr-2"></i>Select view agents transactions to see the transactions of a single agent
    </b-alert>
    <div class="row" >
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Data Table</h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="agentsData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
            <template v-slot:cell(profile._id)="row">
            <router-link :to="`/admin/agent-transactions/${row.value}`">
                <b-button variant="success">
                    <i class="ri-wallet-line align-middle mr-2"></i> View Agent Transactions
                </b-button>
            </router-link>
          </template>
            </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
   
  </Layout>
</template>
